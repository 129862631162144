import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormPrivacy = lazy(
  () => import("./form-privacy-4SS0O3P-.js").then((module) => ({
    default: module.FormPrivacy
  }))
);
function FormPrivacy({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormPrivacy, { ...props });
}
const useFormPrivacyContent = () => {
  return useMemo(
    () => ({
      formPrivacy: {
        component: FormPrivacy
      }
    }),
    []
  );
};
export {
  LazyFormPrivacy as FormPrivacy,
  useFormPrivacyContent
};
